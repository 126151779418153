import React, { Component } from 'react';
import anime from 'animejs';
import styled from 'styled-components';

import Segment from 'segment-js';
import * as vars from '../../styles/vars';

export default class Loop extends Component {
  constructor() {
    super();
    this.loopAnim = null;
    // this.loopAnimation();
    // console.log('this', document.querySelector('#loop'));
    // this.segment.draw('25%', '75% - 10', 1);
    this.timing = 1000;
    this.loopRef = React.createRef();
  }

  componentDidMount() {
    this.setScene();
  }

  handleClick = () => {
    if (this.hasBeen) {
      this.reverse(this.segment);
      this.hasBeen = false;
      console.log('%c REV', 'color: red; font-weight: bold;');
    } else {
      console.log('%c GO', 'color: green; font-weight: bold;');
      this.go(this.segment);
      this.hasBeen = true;
    }
  };

  go = () => {
    this.loopAnim = anime({
      targets: this.loopRef.current,
      autoplay: false,
      keyframes: [
        {
          strokeDasharray: this.segment.strokeDasharray(0, '10%'),
          duration: 2000,
          // easing: 'easeInOutQuad',
        },
        {
          strokeDasharray: this.segment.strokeDasharray(0, '15%'),
          duration: 2000,
          // easing: 'easeInOutQuad',
        },
        {
          strokeDasharray: this.segment.strokeDasharray('40% - 100', '40%'),
          duration: 400,
          stroke: `${vars.colors.brand}`,
          // easing: 'easeInOutQuad',
          easing: 'easeOutElastic(1, .5)',
        },
        {
          delay: 500,
          strokeDasharray: this.segment.strokeDasharray(
            '40% - 150',
            '40% - 50'
          ),
          duration: 100,
          // easing: 'linear',
          // easing: 'easeOutElastic(1, .5)',
        },
        {
          delay: 200,
          strokeDasharray: this.segment.strokeDasharray('80% - 100', '80%'),
          duration: 600,
          // easing: 'linear',
        },

        {
          // delay: 600,
          strokeDasharray: this.segment.strokeDasharray('100%', '100%'),
          duration: 400,
          // opacity: 0,
          easing: 'linear',
        },
        // {
        //   strokeDasharray: this.segment.strokeDasharray('100%', '100%'),
        //   duration: 1000,
        //   // easing: 'easeOutElastic(1, 1)',
        // },
      ],
      easing: 'easeInOutQuad',
      // easing: 'linear',
      loop: false,
    });

    this.shadowLoopAnim = anime({
      targets: '#shadow',
      autoplay: false,
      keyframes: [
        {
          delay: 0,
          strokeDasharray: this.segment.strokeDasharray(20, 100),
          duration: this.timing - 500,
          easing: 'easeInOutQuad',
        },
        {
          strokeDasharray: this.segment.strokeDasharray(1200, 1800),
          duration: this.timing,
          easing: 'easeInOutQuad',
        },
        {
          strokeDasharray: this.segment.strokeDasharray(1400, 2100),
          duration: this.timing,
          // stroke: '#4A3CC6',
        },
        {
          strokeDasharray: this.segment.strokeDasharray('100% - 100', '100%'),
          duration: this.timing,
          easing: 'easeInOutQuad',
        },
        {
          strokeDasharray: this.segment.strokeDasharray('100% - 20', '100%'),
          duration: 300,
          easing: 'easeInOutQuad',
        },
      ],
      easing: 'linear',
      loop: false,
    });

    this.loopAnim.play();
    // this.shadowLoopAnim.play();
  };

  reverse = () => {
    // console.log('Reverse');
    // this.loopAnim.reverse();

    // this.loopAnim.play();

    this.loopAnim = anime({
      targets: '#loop',
      autoplay: false,
      keyframes: [
        {
          strokeDasharray: this.segment.strokeDasharray(0, '10%'),
          duration: this.timing,
          easing: 'easeInOutQuad',
        },
        // {
        //   strokeDasharray: this.segment.strokeDasharray(1800, 2100),
        //   duration: this.timing,
        //   stroke: '#4A3CC6',
        //   // stroke: '#f96',
        //   easing: 'easeInOutQuad',
        // },
        // {
        //   strokeDasharray: this.segment.strokeDasharray(1500, 1800),
        //   duration: this.timing,
        //   // easing: 'easeInOutElastic(1, 0.6)',
        //   easing: 'easeInOutQuad',
        // },
        // {
        //   strokeDasharray: this.segment.strokeDasharray(20, 100),
        //   duration: this.timing - 500,
        //   // easing: 'easeInOutElastic(1, 0.6)',
        //   easing: 'easeInOutQuad',
        // },
      ],

      easing: 'linear',
      // direction: 'forwards',
      loop: false,
    });

    this.loopAnim.play();
  };

  setScene() {
    // this.loop = this.querySelector('#loop');
    // this.segment = new Segment(this.loop);
    // console.log('this', document.querySelector('#loop'));
    // this.segment.draw('25%', '75% - 10', 1);

    // this.loop = document.getElementById('loop');
    // this.shadow = document.getElementById('shadow');
    this.segment = new Segment(this.loopRef.current, 0, 0);
    // this.shadowSegment = new Segment(this.shadow, 0, 0);

    anime.set(this.loopRef.current, {
      stroke: `${vars.colors.greyLight}`,
    });

    // anime.set('#shadow', {
    //   stroke: '#ddd',
    // });
  }

  render() {
    return (
      <LoopWrapper className="c-loop" onClick={this.handleClick} role="button">
        <svg
          className="c-loop__image"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 800 800"
          preserveAspectRatio="none"
        >
          {/* <path
            id="shadow"
            d="M0 720l1258.5-.903c12.774-.373 19.384-7.076 19.83-20.11.447-13.033-6.07-19.55-19.55-19.55-13.034 0-19.551 6.517-19.551 19.55 0 13.034 6.424 19.458 19.271 19.272 13.667-.467 20.5-10.16 20.5-29.082 0-18.921-.223-248.647-.67-689.177l1.67 1440"
            stroke="#f00"
            strokeWidth="3"
            fill="none"
            fillRule="evenodd"
          /> */}
          <path
            ref={this.loopRef}
            d="M0,400 L400,400 C408.730469,400.602865 413.095703,396.23763 413.095703,386.904297 C413.095703,372.904297 400.095703,373.808594 400,373.808594 C399.904297,373.808594 386.882781,373.851625 386.882781,386.925812 C386.882781,400 398.330873,399.714884 400,399.714884 C401.112751,399.714884 534.446084,399.714884 800,399.714884"
            stroke="#fff"
            strokeWidth="3"
            fill="none"
            fillRule="evenodd"
          />
        </svg>
      </LoopWrapper>
    );
  }
}

const LoopWrapper = styled.div`
  /* border: 2px solid ${vars.colors.brand}; */
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
