import React from 'react';
// import { Link } from 'gatsby'

import Layout from '../components/layout';
// import Image from '../components/image'
import Loop from '../components/loop';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Loop />
  </Layout>
);

export default IndexPage;
